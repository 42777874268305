import React from 'react';
import WelcomeMessage from "./WelcomeMessage";
import ServicesInformation from "./ServicesInformation";
import ParallaxContent from "./ParallaxContent";
import Testimonial from "./Testimonial";
import OurTeam from "./OurTeam";
import Photos from "./Photos";
import ContactForm from "./ContactForm";

export default () => {
    return <React.Fragment>

       <WelcomeMessage />

        <ServicesInformation />

        <ParallaxContent image={`/static/template/img/parallax/parallax3.jpg`}>
            <Testimonial quote={`My Trainer is more than a trainer. She's a motivator, an adviser and a friend. Being
                a personal trainer is not just a job
                for her, it's her passion and you see in the way she trains and encourage all of us.`} author={`Karina de la Cruz`} />
        </ParallaxContent>

        <OurTeam />

        <ParallaxContent image={`/static/template/img/parallax/parallax1.jpg`}>
            <Testimonial quote={`Calvet Personal Trainer the best choice I have ever made. My health, my self steam
                                    and my body have improved 100%. Thank you
                                    Otilia! You are the best.`} author={`Teresa Gonzalez`} />
        </ParallaxContent>

        <Photos />

        <ParallaxContent image={`/static/template/img/parallax/parallax4.jpg`}>
            <div className="align-center pad-top40 pad-bot40">
                <blockquote className="bigquote color-white black-stroke">Come join our big family</blockquote>
            </div>
        </ParallaxContent>

        <ContactForm />

        <section id="section-map" className="section appear clearfix">
            <div id="google-map" data-latitude="26.649955" data-longitude="-80.055882" />
        </section>

        <section id="footer" className="section footer">
            <div className="container">
                <div className="row animated opacity mar-bot20" data-andown="fadeIn" data-animation="animation">
                    <div className="col-sm-12 align-center">
                        <ul className="social-network social-circle">
                            <li><a href="https://www.facebook.com/pg/Calvet-Personal-Training-LLC-118259807825/about/"
                                   className="icoFacebook" title="Facebook"><i
                                className="fa fa-facebook"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div className="row align-center copyright">
                    <div className="col-sm-12">
                        <p>Copyright &copy; Calvet Personal Training</p>

                    </div>
                </div>
            </div>

        </section>
    </React.Fragment>
}
