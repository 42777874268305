import React from 'react';

const Photos = () => {
    const photos = [{
        photo: 'static/images/events-portfolio/baile.jpg',
        categories: ['other'],
    }, {
        photo: 'static/images/events-portfolio/img2.jpg',
        categories: ['classes']
    }, {
        photo: 'static/images/events-portfolio/img3.jpg',
        categories: ['exercises']
    }, {
        photo: 'static/images/events-portfolio/img4.jpg',
        categories: ['other']
    }, {
        photo: 'static/images/events-portfolio/spinning_event.jpg',
        categories: ['other']
    }, {
        photo: 'static/images/events-portfolio/img6.jpg',
        categories: ['exercises']
    }, {
        photo: 'static/images/events-portfolio/spinning_right.jpg',
        categories: ['classes']
    }, {
        photo: 'static/images/events-portfolio/img8.jpg',
        categories: ['other']
    }, {
        photo: 'static/images/events-portfolio/img9.jpg',
        categories: ['exercises']
    }, {
        photo: 'static/images/events-portfolio/gym_exer1.jpg',
        categories: ['exercises', 'classes']
    }, {
        photo: 'static/images/events-portfolio/spinning_left.jpg',
        categories: ['exercises', 'classes']
    }, {
        photo: 'static/images/events-portfolio/empty_gym.jpg',
        categories: ['other']
    }];
    return ( <section id="section-works" className="section appear clearfix">
        <div className="container">
            <div className="row mar-bot40">
                <div className="col-md-offset-3 col-md-6">
                    <div className="section-header">
                        <h2 className="section-heading animated" data-animation="bounceInUp">Photos</h2>
                        <p>Always as a family sharing meaningful moments together</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <nav id="filter" className="col-md-12 text-center">
                    <ul>
                        <li><a href="#" className="current btn-theme btn-small" data-filter="*">All</a></li>
                        <li><a href="#" className="btn-theme btn-small" data-filter=".exercises">Exercises</a></li>
                        <li><a href="#" className="btn-theme btn-small" data-filter=".classes">Classes</a></li>
                        <li><a href="#" className="btn-theme btn-small" data-filter=".other">Other</a></li>
                    </ul>
                </nav>
                <div className="col-md-12">
                    <div className="row">
                        <div className="portfolio-items isotopeWrapper clearfix" id="3">
                            {photos.map(p => <Photo {...p} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

const Photo = ({ photo, categories }) => {
    return (<article className={`col-md-4 isotopeItem ${categories.join(' ')}`}>
        <div className="portfolio-item">
            <img src={photo} alt=""/>
            <div className="portfolio-desc align-center">
                <div className="folio-info">
                    <h5><a href="#"></a></h5>
                    <a href={photo} className="fancybox">
                        <i className="fa fa-plus fa-2x" />
                    </a>
                </div>
            </div>
        </div>
    </article>)
}

export default Photos;
