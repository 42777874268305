import { withRouter } from 'next/router'
import Link from 'next/link';

export default () => {
    return <React.Fragment>
        <div style={{ backgroundColor: 'black', height: 60, position: 'sticky', top: 0, zIndex: 10000 }}>
            <div className="container" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <h1>
                        <a href="/">
                            <img src="static/images/logo.png" style={{ width: 75 }} />
                            <span className="black-stroke" style={{ fontSize: 24, fontWeight: 900 }}>Calvet Personal Training</span>
                        </a>
                    </h1>
                </div>
                <div style={{ flex: 1 }}></div>
                <div className="navbar-collapse collapse">
                    <ul className="nav navbar-nav" style={{ height: 60 }}>
                        <li className="active"><a href="/" style={{ height: 60 }}>Home</a></li>
                        <li>
                            <Link href="/schedule">
                                <a style={{ height: 60 }}>Schedule</a>
                            </Link></li>
                        <li>
                            <Link href="/blog">
                                <a style={{ height: 60 }}>Articles</a>
                            </Link>
                        </li>
                        <li><a href="/#section-works" style={{ height: 60 }}>Photos</a></li>
                        <li><a href="https://www.calvetartcollection.com" style={{ height: 60 }}>Art</a></li>
                        <li><a href="/#section-contact" style={{ height: 60 }}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </React.Fragment>
}
