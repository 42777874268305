import React from 'react';

const ContactForm = () => {
    return (<section id="section-contact" className="section appear clearfix">
        <div className="container">
            <div className="row mar-bot20">
                <div className="col-md-offset-3 col-md-6">
                    <div className="section-header">
                        <h2 className="section-heading animated" data-animation="bounceInUp">Contact us</h2>
                        <p><strong>Address:</strong>&nbsp;7428 S. Dixie Hwy, West Palm Beach, FL 33405</p>
                        <p><strong>Phone:</strong>&nbsp;(561) 512-9081</p>
                        <p><strong>Email:</strong>&nbsp;info@calvetpersonaltraining.com</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-md-offset-2">
                    <div className="cform" id="contact-form">
                        <div id="sendmessage">
                            Your message has been sent. Thank you!
                        </div>
                        <div id="errormessage"></div>
                        <form action="" method="post" role="form" className="contactForm">
                            <input name="website" id="website" type="hidden"
                                   value="www.calvetpersonaltraining.com"/>
                            <div className="form-group">
                                <label htmlFor="name">Your Name</label>
                                <input type="text" name="name" className="form-control" id="name"
                                       placeholder="Your Name" data-rule="minlen:4"
                                       data-msg="Please enter at least 4 chars"
                                />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Your Email</label>
                                <input type="email" className="form-control" name="email" id="email"
                                       placeholder="Your Email" data-rule="email"
                                       data-msg="Please enter a valid email"
                                />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Subject</label>
                                <input type="text" className="form-control" name="subject" id="subject"
                                       placeholder="Subject" data-rule="minlen:4"
                                       data-msg="Please enter at least 8 chars of subject"
                                />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea className="form-control" name="message" rows="5" data-rule="required"
                                          data-msg="Please write something for us"></textarea>
                                <div className="validation"></div>
                            </div>

                            <button type="submit" className="btn btn-theme pull-left">SEND MESSAGE</button>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    </section>)
}

export  default  ContactForm;
