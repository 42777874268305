import { Component } from 'react';
import Head from 'next/head';
import Layout from './Layout';
import IndexBody from '../components/IndexBody';

class App extends Component {
  render() {
    return (
      <Layout>
        <Head>
          <title>Calvet Personal Training</title>
        </Head>
        <IndexBody></IndexBody>
      </Layout>
    );
  };
}

export default App;
