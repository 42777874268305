import React from 'react';

const OurTeam = () => {
    const primaryMembers = [{
        name: 'Otilia Calvet',
        picture: 'static/images/team/trainer.jpg',
            title: 'Owner/Personal Trainer'
        },{
            name: 'Ovidio R. Calvet Trelles',
            picture: 'static/images/team/investor.jpg',
            title: 'Investor/Finances'
        },{
            name: 'Ovidio R. Calvet',
            picture: 'static/images/team/tech-guru.jpg',
            title: 'The Tech Guru'
        },{
            name: 'Wendy P. Calvet',
            picture: 'static/images/team/musician.jpg',
            title: 'The Keeper of the Law'
        }];
    const secondaryMembers =  [{
        name: 'Ovidio R. Calvet',
        picture: 'static/images/team/ovi.jpg',
        title: 'Future Trainer'
    },{
        name: 'Jonathan D. Calvet',
        picture: 'static/images/team/jonathan.jpg',
        title: 'Future Trainer'
    }];
    return (<section id="section-about" className="section appear clearfix">
        <div className="container">
            <div className="row mar-bot40">
                <div className="col-md-offset-1 col-md-10">
                    <div className="section-header">
                        <h2 className="section-heading animated" data-animation="bounceInUp">Our Team</h2>
                    </div>
                </div>
            </div>
            <div className="row align-center mar-bot40" style={{ display: 'flex', justifyContent: 'space-around'}}>
                {primaryMembers.map(m => <TeamMember member={m} />)}
            </div>
            <div className="row align-center mar-bot40" style={{ display: 'flex', justifyContent: 'space-around'}}>
                {secondaryMembers.map(m => <TeamMember member={m} />)}
            </div>
        </div>
    </section>)
}

const TeamMember = ({ member }) => {
    return (<div className="col-md-3">
        <div className="team-member">
            <figure className="member-photo"><img src={member.picture} alt=""/></figure>
            <div className="team-detail">
                <h4>{member.name}</h4>
                <span>{member.title}</span>
            </div>
        </div>
    </div>)
}

export default OurTeam;
