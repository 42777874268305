import React from 'react';

const ParallaxContent = ({ children, image }) => {
    return (<section style={{ backgroundImage: `url(${image})`}} className="section parallax" data-stellar-background-ratio="0.5">
        <div className="container">
            <div className="row">
                <div className="col-lg-offset-2 col-lg-8">
                    <div className="align-center">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default ParallaxContent;
