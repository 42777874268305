import React from 'react';

const WelcomeMessage = () => {
    return (
        <section className="featured">
            <div className="container">
                <div className="row mar-bot40">
                    <div className="col-md-8 col-md-offset-2">

                        <div className="align-center">
                            <h2 className="slogan black-stroke">Welcome to Calvet Personal Training</h2>
                            <p style={{fontSize: '1.5em'}}>
                                "Yes, you can do it"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WelcomeMessage;
