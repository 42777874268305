import React from 'react';

const ServicesInformation = () => {
    const services = [{
        title: 'Personal Training',
        icon: <i className="fa fa-area-chart fa-5x mar-bot20" />,
        englishDescription: `We study your physical condition and design tailored exercises and stablish realistic
                                objectives to make sure you accomplish
                                your fitness goals.`,
        spanishDescription: `Estudio la condicion fisica y diseno ejercicios y rutidas a sus necesidades,
							fijo objetivos fisico realista, superviso y demuestro como hacer bien los ejercicios para evitar lesiones.
							Motivo y animo a los clientes en el esfuerzo para lograr los objetivos propuestos.`
    },{
        title: 'Nutritional Advise',
        icon: <i className="fa fa-cutlery fa-5x mar-bot20" />,
        englishDescription: ` We study your physical condition and design tailored exercises and stablish realistic
                                objectives to make sure you accomplish
                                your fitness goals.`,
        spanishDescription: `Oriento y enseno buenos habitos de alimentacion,
							acosanjo un plan nutricional a partir de los requerimientos de cada clientes
                            segun si tiene necesidad de adelgazar o aumentar masa muscular, evitando errores dieteticos.`
    },{
        title: 'Group Classes',
        icon: <i className="fa fa-users fa-5x mar-bot20" />,
        englishDescription: `Enjoy multiple group classes along your workout friends where we combine multiple
                                modalities like indoor cycling, boot camp,
                                aerobics, dance and exercise circuits.`,
        spanishDescription: `Imparto clases de grupo combinando varias modalidades,
							tales como el spinning, boot camp, aerobic, baile, y circuitos que incluye varios ejercicios.`
    }]
    return (
        <section id="section-services" className="section pad-bot30 bg-white">
            <div className="container">
                <div className="row mar-bot40">
                    {services.map(s => <ServiceItem title={s.title} icon={s.icon} description={s.englishDescription} />)}
                </div>
            </div>
        </section>
    )
}

const ServiceItem = ({ title, icon, description }) => {
    return (<div className="col-lg-4">
        <div className="align-center">
            {icon}
            <h4 className="text-bold">{title}</h4>
            <p>
                {description}
            </p>
        </div>
    </div>)
}

export default ServicesInformation;
