import React from 'react';

const Testimonial = ({ quote, author }) => {
    return (
        <div className="testimonial pad-top40 pad-bot40 clearfix">
            <h5 className="black-stroke">
                {quote}
            </h5>
            <br/>
            <span className="author black-stroke">&mdash; {author}</span>
        </div>
    )
}

export default Testimonial;
