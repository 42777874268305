import Header from "../components/Header";

export default ({ children }) => (
  <div>
    <Header></Header>
    <main role="main">
      { children }
    </main>
    {/* <div>Footer</div> */}
  </div>
)